import { Controller } from "@hotwired/stimulus"
import { computePosition, autoUpdate, offset } from "@floating-ui/dom"

// Connects to data-controller="ruby-ui--select-enhancer"
export default class extends Controller {
  connect() {
    // Get ruby-ui--select controller instance attached to the select element.
    const controller = this.application.getControllerForElementAndIdentifier(this.element, "ruby-ui--select")

    // Override controller instance methods.
    controller.setFloatingElement = this.setFloatingElement.bind(controller)
    controller.selectItem = this.selectItem.bind(controller)

    // Override items' handleSelectItem method.
    controller.rubyUiSelectItemOutlets.forEach(item => item.handleSelectItem = this.handleSelectItem.bind(item))

    // Cleanup the select content position auto-updater.
    controller.cleanup()

    // Set the select content position.
    controller.setFloatingElement()
  }

  setFloatingElement() {
    this.cleanup = autoUpdate(this.triggerTarget, this.contentTarget, () => {
      computePosition(
        this.triggerTarget,
        this.contentTarget,
        {
          middleware: [offset(4)],
          placement: "bottom-end" // <- This is the overridden value.
        }
      ).then(({ x, y }) => {
        Object.assign(this.contentTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        })
      })
    })
  }

  selectItem(event) {
    event.preventDefault()

    this.rubyUiSelectItemOutlets.forEach(item => item.handleSelectItem(event))

    const oldValue = this.inputTarget.value
    const newValue = event.currentTarget.dataset.value // <- `currentTarget` is used instead of `target` to get the item element, instead of the clicked element.

    this.inputTarget.value = newValue
    this.valueTarget.innerText = event.currentTarget.dataset.triggerValue // <- Same here for `currentTarget`.

    this.dispatchOnChange(oldValue, newValue)
    this.closeContent()
  }

  handleSelectItem({ currentTarget }) {
    if (this.element.dataset.value == currentTarget.dataset.value) { // <- Same here for `currentTarget`.
      this.element.setAttribute("aria-selected", true);
    } else {
      this.element.removeAttribute("aria-selected");
    }
  }
}
