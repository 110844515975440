import { Controller } from "@hotwired/stimulus"
import { computePosition, autoUpdate, offset } from "@floating-ui/dom"

// Connects to data-controller="teams-dropdown"
export default class extends Controller {
  static targets = [
    "input",
    "accountItem",
    "teamItem",
    "accountsList",
    "accountsEmpty",
    "accountsSearchInput",
    "teamsSearchInput",
    "combobox",
    "accountsButton"
  ]

  triggerFromAccountsButton(event) {
    const comboboxController = this.#getComboboxController()

    if (comboboxController.openValue) return

    this.#disableComboboxClickOnWindowHandlers()
    this.#openComboboxFromAccountsButton(event)
    this.#restoreComboboxClickHandler()
  }

  onTeamItemHover({ currentTarget }) {
    this.#updateTeamVisibility(currentTarget.dataset.value)
    this.#updateTeamSelection(currentTarget)
  }

  onAccountsSearch() {
    const searchValue = this.accountsSearchInputTarget.value.toLowerCase().trim()

    const teamsWithVisibleAccountItems = new Set()

    this.#updateAccountVisibility(searchValue, teamsWithVisibleAccountItems)
    this.#updateEmptyStatesVisibility(teamsWithVisibleAccountItems)
  }

  resetFromOutside(event) {
    const comboboxController = this.#getComboboxController()

    if (comboboxController.openValue) return
    if (this.comboboxTarget.contains(event.target)) return

    this.#reset()
  }

  resetFromTrigger() {
    this.#reset()
  }

  resetFloatingElementPosition() {
    const comboboxController = this.#getComboboxController()

    this.#setupFloatingUI(
      comboboxController.triggerTarget,
      comboboxController.contentTarget
    )
  }

  onCreateTeamTrigger(event) {
    try {
      this.#getComboboxController().onTriggerClick(event)
      this.#reset()
    } catch (error) {
      // Skip, don't worry.
    }

    document.getElementById("team-name-input").focus()
  }

  // Private methods

  #getComboboxController() {
    return this.application.getControllerForElementAndIdentifier(this.comboboxTarget, "ruby-ui--combobox")
  }

  #disableComboboxClickOnWindowHandlers() {
    this.dataAction = this.comboboxTarget.getAttribute("data-action")
    this.comboboxTarget.setAttribute("data-action", "")
  }

  #openComboboxFromAccountsButton(event) {
    const comboboxController = this.#getComboboxController()

    comboboxController.onTriggerClick(event)
    this.accountsSearchInputTarget.focus()

    this.#setupFloatingUI(
      this.accountsButtonTarget,
      comboboxController.contentTarget,
      { xOffset: -(56 * 4) }
    )
  }

  #restoreComboboxClickHandler() {
    setTimeout(() => {
      this.comboboxTarget.setAttribute("data-action", this.dataAction)
    }, 10)
  }

  #updateTeamVisibility(teamName) {
    this.accountsListTargets.forEach((accountsList) => {
      accountsList.classList.toggle("hidden", teamName != accountsList.dataset.teamName)
    })
  }

  #updateTeamSelection(currentTarget) {
    this.teamItemTargets.forEach((teamItem) => {
      const isSelected = teamItem == currentTarget
      this.#setTeamItemState(teamItem, isSelected)
    })
  }

  #setTeamItemState(teamItem, isSelected) {
    teamItem.setAttribute("aria-current", isSelected)
    teamItem.setAttribute("aria-selected", isSelected)

    if (!isSelected) {
      teamItem.removeAttribute("aria-current")
      teamItem.removeAttribute("aria-selected")
    }
  }

  #updateAccountVisibility(searchValue, teamsWithVisibleAccountItems) {
    this.accountItemTargets.forEach((accountItem) => {
      const isVisible = accountItem.dataset.value.toLowerCase().trim().includes(searchValue)

      accountItem.classList.toggle("hidden", !isVisible)
      accountItem.toggleAttribute("hidden", !isVisible)

      if (isVisible) {
        teamsWithVisibleAccountItems.add(accountItem.dataset.teamId)
      }
    })
  }

  #updateEmptyStatesVisibility(teamsWithVisibleAccountItems) {
    this.accountsEmptyTargets.forEach((accountsEmpty) => {
      const hasVisibleAccounts = teamsWithVisibleAccountItems.has(accountsEmpty.dataset.teamId)

      accountsEmpty.classList.toggle("hidden", hasVisibleAccounts)
      accountsEmpty.toggleAttribute("hidden", hasVisibleAccounts)
    })
  }

  #setupFloatingUI(referenceElement, floatingElement, options = {}) {
    const comboboxController = this.#getComboboxController()

    comboboxController.cleanup = autoUpdate(
      referenceElement,
      floatingElement,
      () => {
        computePosition(referenceElement, floatingElement, {
          middleware: [offset(4)],
        }).then(({ x, y }) => {
          Object.assign(floatingElement.style, {
            left: `${x + (options.xOffset || 0)}px`,
            top: `${y}px`,
          })
        })
      }
    )
  }

  #reset() {
    const teamName = this.inputTarget.value

    this.#resetSearchInputs()
    this.#updateTeamVisibility(teamName)
    this.#resetTeamSelection(teamName)
  }

  #resetSearchInputs() {
    const comboboxController = this.#getComboboxController()

    this.teamsSearchInputTarget.value = ""
    comboboxController.rubyUiComboboxContentOutlet.handleSearchInput("")

    this.accountsSearchInputTarget.value = ""
    this.onAccountsSearch()
  }

  #resetTeamSelection(teamName) {
    this.teamItemTargets.forEach((teamItem) => {
      const isSelected = teamName == teamItem.dataset.value

      this.#setTeamItemState(teamItem, isSelected)
    })
  }
}
