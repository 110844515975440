import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ruby-ui--combobox-enhancer"
export default class extends Controller {
  connect() {
    // Get ruby-ui--combobox controller instance attached to the combobox element.
    this.comboboxController = this.application.getControllerForElementAndIdentifier(this.element, "ruby-ui--combobox")
  }

  onSearchInput() {
    this.comboboxController.rubyUiComboboxItemOutlets.forEach((item) => {
      if (item.element.classList.contains("hidden")) {
        item.element.setAttribute("hidden", true)
      } else {
        item.element.removeAttribute("hidden")
      }
    })
  }
}
