// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

import "./controllers"

import "./turbo_flowbite_initialization"
import gtag from "./google_analytics"

import "flowbite/dist/flowbite.turbo.js"

import LocalTime from "local-time"
LocalTime.start()
